import React from "react";
import ReactDOM from "react-dom";
// import MainApp from "./components/mainApp/App";
import App from "./components/app/App";
// import MainApp from 'components/mainApp/App';
import * as serviceWorker from "./serviceWorker";
import "normalize.css";

ReactDOM.render(
  <React.StrictMode>
    {/* <MainApp /> */}
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
serviceWorker.unregister();
