import React, { useContext, useEffect, useState } from "react";
import { Wrapper, Header } from "./style";
import Heading from "../heading/Heading";
import { ThemeContext } from "contexts/themeContext";
import HowToUse from "../howToUse/HowToUse";
import Button from "../button/Button";
import { MoonStars } from "@styled-icons/bootstrap/MoonStars";
import { Sun } from "@styled-icons/feather/Sun";



const AppBar = () => {
  const { toggleTheme, isDarkTheme } = useContext(ThemeContext);

  let buttonText, buttonIcon;

  switch (isDarkTheme) {
    case true:
      buttonText = "Light";
      buttonIcon = Sun;
      break;
    case false:
      buttonText = "Dark";
      buttonIcon = MoonStars;
      break;
  }

  const oldQueryString = window.location.search;
  const urlParams = new URLSearchParams(oldQueryString);
  const user = urlParams.get('user');
  const [username, setUsername] = useState<string | null>(user);

  // useEffect to update the username when the URL parameter changes
  useEffect(() => {
    const paramName = 'user';
    const newUsername = urlParams.get(paramName);
    setUsername(newUsername);
  }, [urlParams]);

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <Wrapper>
      <Header>
        <Heading title={username? capitalizeFirstLetter(username) +"'s visited Countries":"My visited Countries"} />
        <Button
          name="mode"
          icon={buttonIcon}
          padding="5px 10px"
          onClick={toggleTheme}
          posAbsolute={true}
        >
          {buttonText}
        </Button>
      </Header>
    </Wrapper>
  );
};

export default AppBar;
