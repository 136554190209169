import React, { createContext, useState, useEffect, useReducer } from "react";
import {
  continentReducer,
  initialState,
} from "reducers/countriesByContinent";
import {
  getCountryID,
  addToContinent,
  getContinentName,
  findCountryInArray,
  removeCountryFromArray,
  fillWithColor,
  resetMapColoring,
} from "utils/utils";
import useFetchCountry from "../hooks/useFetchCountry";
import useLocalStorage from "../hooks/useLocalStorage";
import useHelpingStates from "../hooks/useHelpingStates";
import { countries as mockCountries} from "_mock/countries";
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';

type ContextTypes = {
  allCountries: ICountry[];
  visitedCountries: ICountry[];
  selectedCountryCode: string | null;
  countriesByContinent: CountriesByContinent | null;
  countryToRemove: CountryToRemove | null;
  addToVisited: IEvent<any>;
  resetHelpingStates: () => void;
  shouldDeleteFromVisited: (e: any, action?: DeleteOrReset) => void;
  deleteFromVisited: (countryToRemove: CountryToRemove | null) => void;
  shouldSaveFromVisited: (e: any, action?: SaveOrExport) => void;
  saveFromVisited: (iCountry: ICountry[] | null) => void;
};

export const VisitedCountryContext = createContext<ContextTypes>({
  allCountries: [],
  visitedCountries: [],
  selectedCountryCode: null,
  countriesByContinent: null,
  countryToRemove: null,
  addToVisited: () => null,
  resetHelpingStates: () => null,
  shouldDeleteFromVisited: () => null,
  deleteFromVisited: () => null,
  shouldSaveFromVisited: () => null,
  saveFromVisited: () => null,
});
type Props = {
  children: React.ReactNode;
};

interface IUpdateVisitedAfterRemove {
  arrToUpdate: ICountry[];
  countryToRemoveID: string;
  setStateFn: {
    (value: React.SetStateAction<ICountry[]>): void;
    (arg0: ICountry[]): void;
  };
}
export const dispatchFn = (object: IDispatchObj) => {
  const {
    type,
    continent,
    continentsState,
    country,
    payloadFn,
    dispatchfn,
  } = object;
  dispatchfn({
    type,
    continent,
    payload: payloadFn(continentsState[continent as ContinentsToShow], country),
  });
};

const buildCountryCodeUrl = (oldUrl: string, countryCode:string[]): string =>{
  const url = new URL(oldUrl);
  const params = new URLSearchParams(url.search);
  // TODO: remove duplicate
  const uniqueSet = new Set(countryCode);
  // Convert the Set back to an array
  const uniqueArray = Array.from(uniqueSet);
  params.set('countries', uniqueArray.join(",")); 
  url.search = params.toString(); 
  const newUrl=url.toString()
  const newPath = newUrl.replace(url.origin, '');
  return newPath
}

const buildNewUrl= (oldUrl: string, countryCode:string[]): string =>{
  const url = new URL(oldUrl);
  const params = new URLSearchParams(url.search);
  // TODO: remove duplicate
  // const uniqueSet = new Set(countryCode);
  // Convert the Set back to an array
  // const uniqueArray = Array.from(uniqueSet);
  // params.set('countries', uniqueArray.join(",")); 
  params.delete('countries')
  url.search = params.toString(); 
  const newUrl=url.toString()
  const newPath = newUrl.replace(url.origin, '');
  return newPath
}

const updateVisitedAfterRemove = (obj: IUpdateVisitedAfterRemove) => {
  const { arrToUpdate, countryToRemoveID, setStateFn } = obj;
  const updatedVisited = removeCountryFromArray(arrToUpdate, countryToRemoveID);
  setStateFn(updatedVisited);
};

const VisitedCountryContextProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the location object
  const oldUrl = window.location.href;

  const [storedValue, setLocalStorage] = useLocalStorage("visited", []);

  const [savedValue, setSavedValue] = useLocalStorage("saved", []);
  const { fetchState, allCountries } = useFetchCountry("");
  
  const {
    lastAddedCountry,
    setLastAddedCountry,
    selectedCountryCode,
    setSelectCountryCode,
    countryToRemove,
    setCountryToRemove,
    resetHelpingStates,
  } = useHelpingStates();

  // why do we need the reducer?
  const [continentsState, dispatch] = useReducer(
    continentReducer,
    initialState
  );

  const [visitedCountries, setVsitedCountries] = useState<ICountry[]>([]);
  const [
    countriesByContinent,
    setCountriesByContinent,
  ] = useState<CountriesByContinent | null>(null);

  const matchCountryAndContinent = (country: ICountry) => {
    const continentName = getContinentName(country) as ContinentsToShow;
    const dispatchObj: IDispatchObj = {
      type: "add",
      continent: continentName,
      continentsState: continentsState,
      country: country,
      payloadFn: addToContinent,
      dispatchfn: dispatch,
    };
    return [
      dispatchFn(dispatchObj),
      fillWithColor(country.code, country.color),
    ];
  };

  // SET INITIAL STATE WITH LOCALSTORAGE
  useEffect(() => {
    // dont use local storage if the country list is not empty
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    const _countries=queryParams.get('countries')
    // console.log("init local countries", _countries);
    console.log("init local countries", savedValue);
    if (_countries !==null ){
      console.log("returning as we would use the url")
      return
    }
    setVsitedCountries(savedValue);
    savedValue.map((country: ICountry) => matchCountryAndContinent(country));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    
    console.log("b4: ", storedValue)
    console.log("all countries", allCountries)
    if (!fetchState || allCountries.length===0) return;
    // offline
    // download all the http link
    // const _allCountries=mockCountries
    // const result = allCountries.map((el: any) => {
    //   const country: ICountry = {
    //     name: el.name,
    //     code: el.alpha2Code,
    //     flag: el.flag,
    //     region: el.region,
    //     subregion: el.subregion,
    //     color: '#428C08',
    //   };
    //   return country;
    // });
    const result = allCountries.map((el: any) => {
      const country: ICountry = {
        ...el,
        color: '#428C08',
      };
      return country;
    });

    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    const _countries=queryParams.get('countries')
    console.log("countries", _countries);
    if (_countries===null || _countries.length ===0){
      console.log("returning")
      return
    }
    
    // Convert the comma-separated string to a list
    const countryCodes= _countries ? _countries.split(',') : [];

    const newPath = buildNewUrl(oldUrl, countryCodes)
    navigate(newPath); 

    console.log("visited countries list", countryCodes)
    console.log("result",result)
    let _visitedCountries = result.filter(country => countryCodes.includes(country.code));
    console.log("set storage",_visitedCountries)

    console.log("target: ", _visitedCountries)
    
    setVsitedCountries(_visitedCountries);
    // _visitedCountries.map((country: ICountry) => matchCountryAndContinent(country));
    console.log("storedValue",storedValue)
    _visitedCountries.map((country: ICountry) => matchCountryAndContinent(country));
    // setLocalStorage(_visitedCountries);
  }, [fetchState,allCountries]);

  useEffect(() => {
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    const _countries=queryParams.get('countries')
    console.log("countries", _countries);
    if (_countries!==null){
      console.log("returning")
      return
    }

    const lastItem = [...visitedCountries].pop();
    console.log("updated visited countries")
    if (visitedCountries.length===0) {
      console.log("omg empty")
    }

    setLocalStorage(visitedCountries); // set localStorage
    if (!lastItem) return;
    setLastAddedCountry(lastItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitedCountries]);

  useEffect(() => {
    setCountriesByContinent(continentsState);
  }, [continentsState]);

  // ADD LAST ADDED COUNTRY TO RELEVANT TABLE BY CONTINENT
  useEffect(() => {
    if (!lastAddedCountry) return;
    matchCountryAndContinent(lastAddedCountry);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastAddedCountry]);

  //TODO: 



  const addToVisited: IEvent<any> = (e) => {
    const target = e.target.dataset.id || e.target.parentNode.dataset.id;
    const countryCode = target;
    countryCode && setSelectCountryCode(countryCode);
    const countryDetails = findCountryInArray(allCountries, countryCode);
    const isVisited = findCountryInArray(visitedCountries, countryCode);
    if (!countryDetails) return;
    const updatedVisited=[...visitedCountries, countryDetails]
    // const updateCountryCodes=updatedVisited.map((c)=> c.code)
    // const newPath = buildCountryCodeUrl(oldUrl, updateCountryCodes)
    // navigate(newPath); 
    !isVisited && setVsitedCountries(updatedVisited);
  };

  const resetAllData = () => {
    resetHelpingStates();
    setVsitedCountries([]);
    // const newPath=buildCountryCodeUrl(oldUrl,[])
    // navigate(newPath); 
    dispatch({ type: "reset" });
    resetMapColoring();
  };

  // Q: what are the differences between this and the should
  // A: should delete is just for the props /menu and setting the id to be deleted
  const deleteFromVisited = (countryToRemove: CountryToRemove | null) => {
    if (!countryToRemove) {
      return resetAllData();
    }
    const { continentName, countryToRemoveID } = countryToRemove;

    const dispatchObj: IDispatchObj = {
      type: "delete",
      continent: continentName,
      continentsState: continentsState,
      country: countryToRemoveID,
      payloadFn: removeCountryFromArray,
      dispatchfn: dispatch,
    };
    dispatchFn(dispatchObj);

    // TODO: refactor
    updateVisitedAfterRemove({
      arrToUpdate: visitedCountries,
      countryToRemoveID,
      setStateFn: setVsitedCountries,
    });

    // const updatedVisited = removeCountryFromArray(visitedCountries, countryToRemoveID);
    // const updatedVisited=[...visitedCountries, countryDetails]
    // const updateCountryCodes=updatedVisited.map((c)=> c.code)
    // const newPath = buildCountryCodeUrl(oldUrl, updateCountryCodes)
    // navigate(newPath); 

    fillWithColor(countryToRemoveID);
    resetHelpingStates();
  };

  // this is just to confirm the deletion
  const shouldDeleteFromVisited = (e: any, action?: DeleteOrReset) => {
    resetHelpingStates();
    if (action === "reset") return;

    const countryToRemoveID: string = getCountryID(e);
    const continentName: ContinentsToShow = getContinentName(
      findCountryInArray(allCountries, countryToRemoveID) as ICountry
    );
    setCountryToRemove({ countryToRemoveID, continentName });
  };

  // TODO: should save
  const shouldSaveFromVisited = (e: any, action?: SaveOrExport) => {
    resetHelpingStates();
    if (action === "reset") return;
    if (action === "save"){
      console.log("should save")
    } ;
    // const countryToSaveID: string = getCountryID(e);
  };

  const saveFromVisited = (visitedCountries: ICountry[]| null) => {
    if (!visitedCountries) {
      return resetAllData();
    }
    resetHelpingStates();
  };

  const value = {
    allCountries,
    visitedCountries,
    selectedCountryCode,
    countriesByContinent,
    countryToRemove,
    addToVisited,
    resetHelpingStates,
    shouldDeleteFromVisited,
    deleteFromVisited,
    shouldSaveFromVisited,
    saveFromVisited,
  };

  return (
    <VisitedCountryContext.Provider value={value}>
      {children}
    </VisitedCountryContext.Provider>
  );
};

export default VisitedCountryContextProvider;
