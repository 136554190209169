import React from "react";
import Button from "../button/Button";
import { ModalBodyWrapper, Text, ButtonsContainer } from "./style";

type DialogConfirmBodyProps = {
  action: DeleteOrReset;
  handleClick: (shouldDelete: ActionConfirm) => void;
  children?: React.ReactElement;
};

const DialogConfirmBody = React.forwardRef<
  HTMLDivElement,
  DialogConfirmBodyProps
>(({ action, handleClick = () => null }, ref) => {
  let deleteText: string = "";
  switch (action){
    case "delete": 
      deleteText="Remove"
    break;
    case "reset":
      deleteText="Clear"
  }

  return (
    <ModalBodyWrapper info={false} ref={ref} tabIndex={-1}>
      <Text id="simple-modal-title">
        Confirm to{" "}
        {action === "delete" ? "remove the country?" : "clear all countries?"}
      </Text>
      <ButtonsContainer>
        <Button name="cancel" onClick={() => handleClick("cancel")}>
          Cancel
        </Button>
        <Button name="confirm" onClick={() => handleClick("confirm")}>
          {/* {action} */}
          {deleteText}
        </Button>
      </ButtonsContainer>
    </ModalBodyWrapper>
  );
});

export default DialogConfirmBody;
