import MainApp from 'components/mainApp/App';
import React from 'react';
import { BrowserRouter as Router, Routes, Route,Navigate } from 'react-router-dom';

const App = ()=> {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/my-visited-countries" />} />
          <Route path="/my-visited-countries" Component={MainApp} />
          {/* <Route path="/" Component={MainApp} /> */}
          <Route path="*" element={<Navigate to="/my-visited-countries" />} />
        </Routes>
      </Router>
    );
  }
export default App;