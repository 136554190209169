import React, { useContext } from "react";
import { VisitedCountryContext } from "../../contexts/visitedCountryContext";
import DeleteContainer from "../delete/DeleteContainer";
import SimpleAccordion from "./SimpleAccordion";
import { Wrapper } from "./style";
import Button from "../button/Button";
import { Copy } from "@styled-icons/feather/Copy"
import { Save } from "@styled-icons/boxicons-regular/Save"
import useLocalStorage from "hooks/useLocalStorage";
import { Grid } from "@material-ui/core";

const Accordions = () => {
  const { visitedCountries, countriesByContinent } = useContext(
    VisitedCountryContext
  );

  const [storedValue, setLocalStorage] = useLocalStorage("saved", []);
  const absolutePath = window.location.href;

  const buildCountryCodeUrl = (oldUrl: string, countryCode:string[]): string =>{
    const url = new URL(oldUrl);
    const params = new URLSearchParams(url.search);
    const uniqueSet = new Set(countryCode);
    const uniqueArray = Array.from(uniqueSet);
    params.set('countries', uniqueArray.join(",")); 
    url.search = params.toString(); 
    const newPath=url.toString()
    return newPath
  }

  const handleSave = () => {
    console.log("save")
    setLocalStorage(visitedCountries)
  };

  const handleCopyToClipboard = () => {
    console.log("copy to keyboard")
    const oldUrl = window.location.href;
    const countryCodes=visitedCountries.map((c)=> c.code)
    const newPath=buildCountryCodeUrl(oldUrl, countryCodes)
    navigator.clipboard.writeText(newPath);
  };

  return (
    <>
      {visitedCountries.length ? (
        <>
          {/* <Button>Copy Url</Button> */}
          <Grid container spacing={1}>
            <Grid item>
            <Button
              name="save"
              onClick={handleSave}
              icon={Save}
              padding= "5px 10px"
            >
            save
            </Button>
            </Grid>
            <Grid item>
              <Button
                name="copy"
                onClick={handleCopyToClipboard}
                icon={Copy}
                padding= "5px 10px"
              >
                Copy url
          </Button>
            </Grid>
            <Grid item xs justify="flex-end">
              <DeleteContainer action="reset" />
            </Grid>
          </Grid>

          <Wrapper>
            {countriesByContinent &&
              Object.entries(countriesByContinent).map(
                ([continentName, countries]) => {
                  return countries.length ? (
                    <SimpleAccordion
                      key={continentName}
                      continentName={continentName}
                      visitedCountry={countries}
                    />
                  ) : null;
                }
              )}
          </Wrapper>
        </>
      ) : null}
    </>
  );
};

export default Accordions;
